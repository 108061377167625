import "bootstrap";

import {createApp} from "vue"
import router from './router'
import store from './store'
import App from "./App.vue"
import VueLazyLoad from 'vue3-lazyload'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret, faMagnifyingGlass, faSignOut, faWindowClose, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faUserSecret, faMagnifyingGlass, faSignOut, faWindowClose, faEnvelope,faLock)

import VueCookies from 'vue3-cookies'

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(VueLazyLoad)
    .use(router)
    .use(store)
    .use(VueCookies, {
        // expireTimes: 60 * 60 * 24 * 360,
        // path: "/",
        // domain: "",
        // secure: true,
        // sameSite: "None"
    })
    .mount("#app")