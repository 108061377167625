import { createStore } from 'vuex'
import publicacionesStore from './modules/publicaciones'
import modalStore from './modules/modal'
import miscellaneousStore from './modules/miscellaneous'

export default createStore({
  modules: {
    publicacion: publicacionesStore,
    modal: modalStore,
    miscellaneous: miscellaneousStore
  }
})
